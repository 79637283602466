import * as moment from 'moment-timezone';

export class ONTStatusClass {

  private _status: ONTStatusEnum;
  private _intervalStartMoment: moment.Moment;

  // Same fields as ONTStatusJson
  public ontStatus: string;
  public ontStatusDate: string;
  public intervalStart: string;
  public ontStatusDescription: string;
  public ontStatusSummary: string;
  public ontStatusColourName: string;
  public ontStatusColourHex: string;
  public ontStatusListOrder: string;
  public ontStatusSeverity: string;
  public ontStatusType: string;
  public method: string;

  constructor(theStatusJson: ONTStatusJson)
  {
    this.ontStatusDate = theStatusJson.ontStatusDate;
    this.intervalStart = theStatusJson.intervalStart;
    if (!this.intervalStart)
    {
      this.intervalStart = this.ontStatusDate;
    }
    this._intervalStartMoment = new moment(this.intervalStart);
    this._status = ONTStatusClass.transformFromString(theStatusJson.ontStatus);
    this.ontStatus = theStatusJson.ontStatus;
    this.ontStatusDescription = ONTStatusClass.statusDescription(this._status);
    this.ontStatusSummary = ONTStatusClass.statusSummary(this._status);

    this.ontStatusColourName = theStatusJson.ontStatusColourName;
    this.ontStatusColourHex = theStatusJson.ontStatusColourHex;
    this.ontStatusListOrder = theStatusJson.ontStatusListOrder;
    this.ontStatusSeverity = theStatusJson.ontStatusSeverity;
    this.ontStatusType = theStatusJson.ontStatusType;
    this.method = theStatusJson.method;

  }

  get status(): ONTStatusEnum {
      return this._status;
  }
  set status(value: ONTStatusEnum) {
      this._status = value;
  }

  get statusName(): string {
      return ONTStatusClass.transformToString(this._status);
  }
  set statusName(value: string) {
      this._status = ONTStatusClass.transformFromString(value);
  }

  get intervalStartMoment(): moment {
    return this._intervalStartMoment;
  }

  public static createFromString(theONTStatusString: string) : ONTStatusClass
  {
    const theONTStatusEnum = ONTStatusClass.transformFromString(theONTStatusString);

    let theONTStatusJSON: ONTStatusJson = new ONTStatusJson();
    theONTStatusJSON.ontStatus = ONTStatusClass.transformToString(theONTStatusEnum);
    theONTStatusJSON.ontStatusSummary = ONTStatusClass.statusSummary(theONTStatusEnum);
    theONTStatusJSON.ontStatusDescription = ONTStatusClass.statusDescription(theONTStatusEnum);

    const theONTStatusClass: ONTStatusClass = new ONTStatusClass(theONTStatusJSON);
    return theONTStatusClass;
  }

  private static transformToString(status: ONTStatusEnum) : string
  {
    let result: string;
    switch (status)
    {
      case ONTStatusEnum.InService:
        result = 'In Service';
        break;
      case ONTStatusEnum.LowRobustness:
        result = 'Low Robustness';
        break;
      case ONTStatusEnum.DegradedService:
        result = 'Degraded Service';
        break;
      case ONTStatusEnum.NoService:
        result = 'No Service';
        break;
      case ONTStatusEnum.PoweredOff:
        result = 'Powered Off';
        break;
      case ONTStatusEnum.Rebooting:
        result = 'Rebooting';
        break;
      case ONTStatusEnum.NotTestable:
      default:
        result = 'Not Testable';
    }
    return result;
  }

  private static transformFromString(statusString: string) : ONTStatusEnum
  {
    // console.log('transformFromString gets ', statusString.toLowerCase());
    let result: ONTStatusEnum;
    if (!statusString)
    {
      // console.log('transformFromString empty statusString!!!!');
      return ONTStatusEnum.NotTestable;
    }
    switch (statusString.toLowerCase())
    {
      case 'in service':
        result = ONTStatusEnum.InService;
        break;
      case 'low robustness':
        result = ONTStatusEnum.LowRobustness;
        break;
      case 'degraded service':
        result = ONTStatusEnum.DegradedService;
        break;
      case 'no service':
        result = ONTStatusEnum.NoService;
        break;
      case 'powered off':
        result = ONTStatusEnum.PoweredOff;
        break;
      case 'rebooting':
        result = ONTStatusEnum.Rebooting;
        break;
      case 'not testable': // Fall Through
      case 'not tested':   // Fall Through
      default:             // Unrecognised statuses get turned into Not Testable
        result = ONTStatusEnum.NotTestable;
    }
    // console.log('transformFromString returns ', result);
    return result;
  }

  private static statusDescription(theStatus: ONTStatusEnum): string {
    let result: string;
    switch (theStatus) {
      case ONTStatusEnum.InService:
        result = 'Green is good';
        break;
      case ONTStatusEnum.LowRobustness:
        result = 'Yellow is lower robustness with no errors';
        break;
      case ONTStatusEnum.DegradedService:
        result = 'Orange indicates detected transmission errors';
        break;
      case ONTStatusEnum.NoService:
        result = 'Red is interrupted';
        break;
      case ONTStatusEnum.PoweredOff:
        result = 'Black is ONT switched off';
        break;
      case ONTStatusEnum.Rebooting:
        result = 'Blue is ONT rebooting';
        break;
      case ONTStatusEnum.NotTestable:
      default:
        result = 'Grey is the system cannot collect data';
    }
    return result;
  }

  private static statusSummary(theStatus: ONTStatusEnum): string {
    let result: string;
    switch (theStatus) {
      case ONTStatusEnum.InService:
        result = 'Green indicates that there is no fault with ONT connectivity. We expect no impact to end-customer services from physical connectivity on the Northpower Fibre Network';
        break;
      case ONTStatusEnum.LowRobustness:
        result = 'Yellow indicates optical signal power is lower than historical. This is not expected to impact the end-customer service experience.';
        break;
      case ONTStatusEnum.DegradedService:
        result = 'Orange indicates that errors are detected. The end-customer experience may be impacted in a number of different ways. We recommend you complete tier 1 troubleshooting before logging a fault.';
        break;
      case ONTStatusEnum.NoService:
        result = 'A loss of connectivity to the ONT has been detected impacting the end-consumer\'s service. Please ensure the ONT is powered on prior to logging a fault.';
        break;
      case ONTStatusEnum.PoweredOff:
        result = 'This status indicates that the end-consumer has turned off their ONT. This status is triggered by a dying gasp alarm being received by the OLT. Ask the end-consumer to power on the ONT.';
        break;
      case ONTStatusEnum.Rebooting:
        result = 'Blue indicates that Northpower Fibre has rebooted the ONT.';
        break;
      case ONTStatusEnum.NotTestable:
      default:
        result = 'This status indicates that the Fibre Test Tools is unable to poll the ONT at this time. Requires on-site troubleshooting to identify the root cause.';
    }
    return result;
  }

}

export class ONTStatusJson {
  public ontStatus: string;
  public ontStatusDate: string;
  public intervalStart: string;
  public ontStatusDescription: string;
  public ontStatusSummary: string;
  public ontStatusColourName: string;
  public ontStatusColourHex: string;
  public ontStatusListOrder: string;
  public ontStatusSeverity: string;
  public ontStatusType: string;
  public method: string;
}

export enum ONTStatusEnum {
  InService       = 'in-service',
  LowRobustness   = 'low-robustness',
  DegradedService = 'degraded-service',
  NoService       = 'no-service',
  PoweredOff      = 'powered-off',
  NotTestable     = 'not-testable',
  Rebooting       = 'rebooting',
};

