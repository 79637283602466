import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-port-status-key-icon',
  templateUrl: './port-status-key-icon.component.html',
  styleUrls: ['./port-status-key-icon.component.scss']
})
export class PortStatusKeyIconComponent implements OnInit {

  @Input() statusClass: string; // e.g. 'device', 'provisioning', 'service'
  @Input() status: string; // e.g. 'Device Detected'

  constructor() { }

  ngOnInit() {
  }

}
