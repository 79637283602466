import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusToLabel'
})
export class StatusToLabelPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    let result = '';

    if (value)
    {
      result = value.replace(/-/g, ' ').replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
    return result;
  }

}
