import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct, NgbDateAdapter, NgbDate } from "@ng-bootstrap/ng-bootstrap";

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return "";
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}


function parseNorthpowerDate(value: string): NgbDateStruct {
  if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
          return {year: toInteger(dateParts[0]), month: null, day: null};
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
          return {year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null};
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
          return {year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0])};
      }
  }   
  return null;
}

function formatNorthpowerDate(date: NgbDateStruct): string {
        let stringDate: string = ""; 
        if (date) {
            stringDate += isNumber(date.day) ? padNumber(date.day) + "/" : "";
            stringDate += isNumber(date.month) ? padNumber(date.month) + "/" : "";
            stringDate += date.year;
        }
        return stringDate;
}

@Injectable()
export class NgbDateNorthpowerParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {
      return parseNorthpowerDate(value);
    }

    format(date: NgbDateStruct): string {
      return formatNorthpowerDate(date);
    }
}

export class NgbDateNorthpowerAdapter<D> extends NgbDateAdapter<D> {


  fromModel(value: any): NgbDateStruct
  {
    const parsedDate: NgbDateStruct = parseNorthpowerDate(value);
    return parsedDate;
  }

  toModel(date: NgbDateStruct): any
  {
    const formattedDate = formatNorthpowerDate(date);
    return formattedDate;
  }

}

