import { Injectable } from '@angular/core';

//
// Needed to change resolveJsonModule and allowSyntheticDefaultImports in tsconfig.json to allow this.
//
import theVersionInfo from '../../git-version.json';

@Injectable({
  providedIn: 'root'
})
export class VersionInfoService {

  constructor() { }


  public getVersionInfo() {
    return theVersionInfo;
  }

}
