import { Pipe, PipeTransform } from '@angular/core';
import { DataPortStatus, VoicePortStatus } from './port-status-list';

@Pipe({
  name: 'portStatus'
})
export class PortStatusPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    let result = value;
    switch (value) {
      case DataPortStatus.ProvisionedDeviceDetected:
        // result = 'Provisioned Device Detected';
        result = 'In Service';
        break;
      case DataPortStatus.ProvisionedNoDevice:
        // result = 'Provisioned No Device';
        result = 'No Service';
        break;
      case DataPortStatus.ProvisionedDegradedService:
        result = 'Degraded Service';
        break;
      case DataPortStatus.NotYourServiceDeviceDetected:
        // result = 'Not Your Service Device Detected';
        // result = 'No Service';
        result = 'Not Your Service';
        break;
      case DataPortStatus.NotYourServiceNoDevice:
        // result = 'Not Your Service No Device';
        // result = 'No Service';
        result = 'Not Your Service';
        break;
      case DataPortStatus.NotProvisionedDeviceDetected:
        // result = 'Not Provisioned Device Detected';
        result = 'No Service';
        break;
      case DataPortStatus.NotProvisionedNoDevice:
        // result = 'Not Provisioned No Device';
        result = 'No Service';
        break;
      case DataPortStatus.NotTestable:
        result = 'Not Testable';
        break;

      case VoicePortStatus.Provisioned:
        // result = 'Provisioned';
        result = 'In Service';
        break;
      case VoicePortStatus.ProvisionedNotConnected:
        // result = 'Provisioned Not Connected';
        result = 'No Service';
        break;
      case VoicePortStatus.NotYourService:
        result = 'Not Your Service';
        break;
      case VoicePortStatus.NotProvisioned:
        // result = 'Not Provisioned';
        result = 'No Service';
        break;
      case VoicePortStatus.NotTestable:
        result = 'Not Testable';
        break;
        
      case VoicePortStatus.Loading:
        result = 'Loading';
        break;
    }
    return result;
  }

}

