import { Pipe, PipeTransform } from '@angular/core';
import { ONTStatusClass, ONTStatusEnum, ONTStatusJson } from './ont-status-class';

// Mick Temp: This is most likely not needed. Can just use ONTStatusClass.statusName.

@Pipe({
  name: 'ontStatus'
})
export class ONTStatusPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    let statusJson: ONTStatusJson;
    statusJson.ontStatus = value;
    statusJson.ontStatusDescription = '';
    statusJson.ontStatusSummary = '';

    let status = new ONTStatusClass(statusJson);
    return status.statusName;
  }
}
