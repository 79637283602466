import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject, BehaviorSubject, combineLatest, of, empty, zip, throwError } from 'rxjs';
import { map, mergeMap, shareReplay, delay, catchError } from 'rxjs/operators';

import * as moment from 'moment-timezone';

import { SspaLoggerService } from '../sspa-logger.service';
import { B2BAPIService } from '../b2b-api.service';
import { EnIconComponent } from '../en-icon/en-icon.component';
import { ONTStatusClass } from '../ont-status-class';

@Component({
  selector: 'app-faults-report',
  templateUrl: './faults-report.component.html',
  styleUrls: ['./faults-report.component.scss']
})
export class FaultsReportComponent implements OnInit {

  public fsl_instance: string = null;
  public diagnostics_id: string = null;
  public brand: string = null;
  public service_affected: string = null;
  public service_id: string = null;
  public circuit_id: string = null;
  public invalidFormControls: Array<String> = [];
  public submitHasBeenClicked: boolean = false;
  public faultIsBeingPosted: boolean = false;

  public userDetails: Observable<any>;
  
  public queriedServicesLoading: boolean = false;
  public queriedServices: Observable<any>;
  // public queriedPlaceLoading: boolean = false;
  public queriedPlace: Observable<any>;
  public servicesAddress: Observable<string>;
  public faultyService: Observable<any>; // The particular service (taken from queriedServices) user is reporting faulty
  public ontStateCurrent: Observable<ONTStatusClass>;

  public faultsReportForm: FormGroup;

  // @ViewChild('demoDiv', {static: false}) theDemoDiv: ElementRef; 
  // @ViewChild('demoDateInput', {static: false}) theDemoDateInput: ElementRef; 



  constructor(private activatedRoute: ActivatedRoute, private router: Router, public b2bApi: B2BAPIService, private sspaLogger: SspaLoggerService) { }

  ngOnInit() {
    // this.sspaLogger.debug('FaultsReportComponent ngOnInit this.activatedRoute.snapshot.paramMap', this.activatedRoute.snapshot.paramMap);
    this.fsl_instance = this.activatedRoute.snapshot.paramMap.get('fsl_instance');
    this.diagnostics_id = this.activatedRoute.snapshot.paramMap.get('diagnostics_id');
    this.brand = this.activatedRoute.snapshot.paramMap.get('brand');
    this.service_affected = this.activatedRoute.snapshot.paramMap.get('service_affected');
    this.service_id = this.activatedRoute.snapshot.paramMap.get('service_id');
    this.circuit_id = this.activatedRoute.snapshot.paramMap.get('circuit_id');

    let dateNow = moment().format('DD/MM/YYYY');
    let timeNow = moment().format('h:mm A');

    this.faultsReportForm = new FormGroup({
      fsl: new FormControl(''), // Added via queryPlace
      fslInstanceId: new FormControl(this.fsl_instance),
      diagnosticsId: new FormControl(this.diagnostics_id),
      brand: new FormControl(this.brand),
      serviceAffected: new FormControl(this.service_affected),
      serviceId: new FormControl(this.service_id),
      circuitId: new FormControl(this.circuit_id),
      faultType: new FormControl('', Validators.required),
      dateOccurred: new FormControl(dateNow, [Validators.required, Validators.pattern("\\d{2}/\\d{2}/\\d{4}")]),
      timeOccurred: new FormControl(timeNow, [Validators.required, Validators.pattern('[0-1]?\\d:[0-5]\\d [aApP][mM]')]),
      faultDescription: new FormControl('', Validators.required),
      criticalResponseRequested: new FormControl(''),
      selfIsolation: new FormControl('', Validators.required),
      isEmergency: new FormControl(''),
      emergencyFaultReason: new FormControl(''), // Validator is set/cleared in onFaultTypeChange fnction
      comment: new FormControl(''),
      accessSeekerName: new FormControl('', Validators.required),
      accessSeekerPhone: new FormControl('', Validators.required),
      accessSeekerEmail: new FormControl('', [Validators.required, Validators.email]),
      accessSeekerReferenceNumber: new FormControl(''),
      customerName: new FormControl('', Validators.required),
      customerEmail: new FormControl(''),
      customerPhone: new FormControl('', Validators.required),
      altCustomerPhone: new FormControl(''),
      customerType: new FormControl('', Validators.required),
      siteContactName: new FormControl('', Validators.required),
      siteContactPhone: new FormControl('', Validators.required),
      shortAddress: new FormControl(''),
      ontOpticalIndicator: new FormControl('', Validators.required),
    }, {
      //validator: faultsReportFormValidator,
    });

    if (!this.fsl_instance)
    {
      this.sspaLogger.error('Sorry, we can\'t find a location for your fault report.');
      return;
    }

    let queryServiceParams: any = {};
    queryServiceParams.fsl_instance = this.fsl_instance;

    this.queriedServicesLoading = true;
    this.queriedServices = this.b2bApi.getQueryServices(queryServiceParams).pipe(
      // delay(2000),
      catchError(err => {
        this.sspaLogger.debug('FaultsReportComponent queriedServices caught error', err);
        return of('NoData');
      }),
      map(services => {
        this.queriedServicesLoading = false;
        return services;
      }),
    );

    //
    // Call queryPlace so we can show the address of this fsl
    //
    // this.queriedPlaceLoading = true;
    this.queriedPlace = this.queriedServices.pipe(
      // delay(4000),
      mergeMap(theQueriedServices => {
        // this.queriedPlaceLoading = false;
        if (theQueriedServices == 'NoData')
        {
          return of('NoData');
        }
        this.faultsReportForm.patchValue({fsl: theQueriedServices.fibreServiceLocationIdentifier});
        const getParams: any = {'fsl': theQueriedServices.fibreServiceLocationIdentifier};
        // this.sspaLogger.debug('FaultsReport queriedPlace is in mergeMap with getParams', getParams);
        return this.b2bApi.getQueryPlace(getParams).pipe(
          catchError(err => {
            this.sspaLogger.debug('FaultsReport queriedPlace caught error', err);
            return of('NoData');
          }),
        );
      }),
      shareReplay(1)
    );

    //
    // Reduce QueryService result to just the faulty service (including all its circuits)
    //
    this.faultyService = this.queriedServices.pipe(
      map(theQueriedServices => {
        const faultyONT = theQueriedServices.ONTServices.find(thisONTService => {
          return thisONTService.services.find(thisService => {
            return thisService.serviceIdentifier == this.service_id;
          });
        });
        const faultyService = faultyONT.services.find(thisService => {
          return thisService.serviceIdentifier == this.service_id;
        });
        // faultyONT.ontModel = 'Calix T063G'; // Mick Temp: Debug
        // faultyONT.ontModel = 'Calix T072G'; // Mick Temp: Debug
        let ontLightsImgFile: string = '';
        const ontModelLowerCase = faultyONT.ontModel.toLowerCase().replace(' ', '-');
        switch (ontModelLowerCase)
        {
          case 'calix-t063g':
            // ontLightsImgFile = '/assets/img/ont-t063-lights-360x145.jpg';
            ontLightsImgFile = '/assets/img/t063-lights.svg';
            break;
          case 'calix-t072g':
            // ontLightsImgFile = '/assets/img/ont-t072-lights-636x254.jpg';
            ontLightsImgFile = '/assets/img/t072-lights.svg';
            break;
          case 'calix-812g':
            // ontLightsImgFile = '/assets/img/ont-812g-lights-365x146.jpg';
            ontLightsImgFile = '/assets/img/812g-lights.svg';
            break;
        }
        return {
          'fibreServiceLocationIdentifier': theQueriedServices.fibreServiceLocationIdentifier,
          'fslInstanceIdentifier': faultyONT.fslInstanceIdentifier,
          'ontModel': faultyONT.ontModel,
          'ontLightsImgFile': ontLightsImgFile,
          service: faultyService,
        }
      }),
    )

    this.servicesAddress = this.queriedPlace.pipe(
      // catchError(err => of('NoData')),
      map(theQueriedPlace => {
        let address: string = 'Address Not Found';
        if (theQueriedPlace == 'NoData' || !theQueriedPlace.queryPlaceResponse)
        {
          return address;
        }
        if (theQueriedPlace.queryPlaceResponse.placeInteractions.length > 0)
        {
          let firstPlaceInteraction = theQueriedPlace.queryPlaceResponse.placeInteractions[0];
          if (firstPlaceInteraction.placeInteractionItems.length > 0)
          {
            // console.log('servicesAddress got firstInteractionItem');
            const firstInteractionItem = firstPlaceInteraction.placeInteractionItems[0];
            if (firstInteractionItem.address.addressString)
            {
              address = firstInteractionItem.address.addressString;
            }
          }
        }
        this.faultsReportForm.patchValue({shortAddress: address});
        return address;
      })
    );

    this.userDetails = this.b2bApi.getLoggedInUserDetails().pipe(
      map(theUserDetails => {
        // this.sspaLogger.debug('getLoggedInUserDetails pipe map theUserDetails', theUserDetails);
        this.accessSeekerName.setValue(theUserDetails.ee_screen_name);
        this.accessSeekerPhone.setValue(theUserDetails.phone_number);
        this.accessSeekerEmail.setValue(theUserDetails.ee_email);

        return theUserDetails;
      }),
    );

    const notTestable: ONTStatusClass = ONTStatusClass.createFromString('not-testable');
    let stateCurrentParams: any = {
      fslInstanceId: this.fsl_instance,
    };
    this.ontStateCurrent = this.b2bApi.getONTStateCurrentWithRefresh(stateCurrentParams).pipe(
      catchError(err => of(notTestable)),
      map((theONTStatusJson: any) => {
        const theONTStatusClass: ONTStatusClass = new ONTStatusClass(theONTStatusJson);
        // if (theONTStatusClass.ontStatus == 'In Service')
        // {
        //   //
        //   // If the ONT is in service we won't ask about ONT Warning Lights.
        //   // So we remove ontOpticalIndicator from the required form fields.
        //   //
        //   this.ontOpticalIndicator.clearValidators();
        //   this.ontOpticalIndicator.updateValueAndValidity();
        // }
        return theONTStatusClass;
      })
    );

    this.faultsReportForm.statusChanges.subscribe( (status) => {
      // this.sspaLogger.debug('faultsReportForm.statusChanges', status); // status will be "VALID", "INVALID", "PENDING" or "DISABLED"
      //
      // Keep track of invalid form controls so we can display an error label
      // next to the form submit button.
      //
      this.invalidFormControls = this.findInvalidFormControls();
    })
  }

  get faultType() { return this.faultsReportForm.get('faultType'); }
  get dateOccurred() { return this.faultsReportForm.get('dateOccurred'); }
  get timeOccurred() { return this.faultsReportForm.get('timeOccurred'); }
  get faultDescription() { return this.faultsReportForm.get('faultDescription'); }
  get isEmergency() { return this.faultsReportForm.get('isEmergency'); }
  get emergencyFaultReason() { return this.faultsReportForm.get('emergencyFaultReason'); }
  get selfIsolation() { return this.faultsReportForm.get('selfIsolation'); }
  get customerName() { return this.faultsReportForm.get('customerName'); }
  get customerEmail() { return this.faultsReportForm.get('customerEmail'); }
  get customerPhone() { return this.faultsReportForm.get('customerPhone'); }
  get siteContactName() { return this.faultsReportForm.get('siteContactName'); }
  get siteContactPhone() { return this.faultsReportForm.get('siteContactPhone'); }
  get customerType() { return this.faultsReportForm.get('customerType'); }
  get accessSeekerName() { return this.faultsReportForm.get('accessSeekerName'); }
  get accessSeekerPhone() { return this.faultsReportForm.get('accessSeekerPhone'); }
  get accessSeekerEmail() { return this.faultsReportForm.get('accessSeekerEmail'); }
  get ontOpticalIndicator() { return this.faultsReportForm.get('ontOpticalIndicator'); }

  public onSubmitFaultsReport()
  {
    this.sspaLogger.debug('onSubmitFaultsReport', this.faultsReportForm.value);
    this.submitHasBeenClicked = true;
    if (this.faultsReportForm.valid) {
      this.faultIsBeingPosted = true;

      if (this.ontOpticalIndicator.value == '') {
        //
        // User has not answered the ONT Warning Lights question so we default the answer to Unknown.
        // Should never now happen since we always ask this question.
        //
        this.ontOpticalIndicator.setValue('Unknown');
      }

      this.b2bApi.postNewFaultRequest(this.faultsReportForm.value).subscribe(
        newFaultResponse => {
          this.sspaLogger.debug('onSubmitFaultsReport post response', newFaultResponse);
          if (newFaultResponse == 'SspaHttpError')
          {
            this.sspaLogger.tell_sentry('onSubmitFaultsReport failed to postNewFaultRequest');
          }
          else if (newFaultResponse.b2bFaultRefNum)
          {
            this.sspaLogger.debug('new fault submission succeeded');
            this.router.navigate(['/fault_submitted', {type: 'new', fault_id: newFaultResponse.b2bFaultRefNum}]);
          }
          else
          {
            const errorDetail = newFaultResponse.message ? newFaultResponse.message : '';
            this.sspaLogger.error('There was a problem submitting your fault report.', errorDetail);
          }
          this.faultIsBeingPosted = false;
        });
    } else {
      this.touchFormControls();
      this.sspaLogger.debug('invalid controls', this.invalidFormControls);
    }
  }

  public onFaultTypeChange()
  {
    this.sspaLogger.debug('onFaultTypeChange', this.faultType.value);
  }

  public onIsEmergencyChange()
  {
    this.sspaLogger.debug('onIsEmergencyChange', this.isEmergency.value);
    if (this.isEmergency.value == true) {
      this.sspaLogger.debug('onIsEmergencyChange emergencyFaultReason required');
      this.emergencyFaultReason.setValidators(Validators.required);
    } else {
      this.sspaLogger.debug('onIsEmergencyChange emergencyFaultReason clearValidators');
      this.emergencyFaultReason.clearValidators();
    }
    this.emergencyFaultReason.updateValueAndValidity();
  }

  public touchFormControls() {
    const controls = this.faultsReportForm.controls;
    for (const name in controls) {
        controls[name].markAsTouched();
    }
  }

  // public ngbTimepickerModelChange(event) {
  //   this.sspaLogger.debug('ngbTimepickerModelChange', event);

  //   const amPm = (event.hour < 12) ? 'AM' : 'PM';
  //   const modHour = event.hour % 12;
  //   const formTimeString = modHour.toString().padStart(2, '0') +   ':' + event.minute.toString().padStart(2, '0') + ' ' + amPm;
  //   this.timeOccurred.setValue(formTimeString);
  // }

  public findInvalidFormControls() {
    const invalid = [];
    const controls = this.faultsReportForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
  }

  public onDateSelect(event) {
    this.sspaLogger.debug('onDateSelect');
  }

  // public onDemoDatePickerClick() {
  //   this.sspaLogger.debug('onDemoDatePickerClick');
  //   // this.sspaLogger.debug('onDemoDatePickerClick this.theDemoDatePicker', this.theDemoDatePicker);
  //   this.sspaLogger.debug('onDemoDatePickerClick this.theDemoDiv', this.theDemoDiv);
  //   this.sspaLogger.debug('onDemoDatePickerClick this.theDemoDateInput', this.theDemoDateInput);
  //   this.theDemoDiv.nativeElement.textContent = 'Set by onDemoDatePickerClick';
  //   this.theDemoDateInput.nativeElement.value = 'Date from onDemoDatePickerClick';

  //   // TinyDatePicker(this.theDemoDateInput.nativeElement);
  //   // this.demoDatePicker.open();
  // }


}
















