import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { ONTStatusClass } from '../ont-status-class';
import { Observable } from 'rxjs';
import { SspaLoggerService } from '../sspa-logger.service';

@Component({
  selector: 'app-ont-hourly-history',
  templateUrl: './ont-hourly-history.component.html',
  styleUrls: ['./ont-hourly-history.component.scss']
})
export class OntHourlyHistoryComponent implements OnInit {

  public theSelectedHour: ONTStatusClass;

  @Input() hourlyHistory: ONTStatusClass[];
  @Output() selectedHour: EventEmitter<ONTStatusClass> = new EventEmitter<ONTStatusClass>();

  constructor(private sspaLogger: SspaLoggerService) { }

  ngOnInit() {
  }

  public onHourClick(hour: ONTStatusClass)
  {
    this.sspaLogger.debug('onHourClick got hour', hour);
    this.theSelectedHour = hour;
    this.selectedHour.next(hour);
  }

}

