import { Component, OnInit, Input } from '@angular/core';
import { ONTStatusClass, ONTStatusEnum, ONTStatusJson } from '../ont-status-class';
import { Observable } from 'rxjs';
import { SspaLoggerService } from '../sspa-logger.service';

@Component({
  selector: 'app-ont-status-badge',
  templateUrl: './ont-status-badge.component.html',
  styleUrls: ['./ont-status-badge.component.scss']
})
export class ONTStatusBadgeComponent implements OnInit {

  // private lowRobustnessSubscription;

  @Input() status: ONTStatusClass;
  // @Input() lowRobustness: Observable<boolean>;

  constructor(private sspaLogger: SspaLoggerService) { }

  ngOnInit() {
    // this.lowRobustnessSubscription = this.lowRobustness.subscribe(isLowRobustness => {
    //   // this.sspaLogger.debug('ONTStatusBadgeComponent lowRobustness subscription gets', isLowRobustness ? 'true' : 'false');
    //   if (isLowRobustness)
    //   {
    //     if ([ONTStatusEnum.InService].includes(this.status.status))
    //     {
    //       this.status = ONTStatusClass.createFromString('low-robustness');
    //     }
    //   }
    //   else
    //   {
    //     if ([ONTStatusEnum.LowRobustness].includes(this.status.status))
    //     {
    //       this.status = ONTStatusClass.createFromString('in-service');
    //       this.status.status = ONTStatusEnum.InService;
    //     }

    //   }
    // })
  }

  ngOnDestroy() {
    // this.lowRobustnessSubscription.unsubscribe();
  }
    
}
