import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
// import { SSPAuthService } from './sspauth.service';
import { B2BAPIService } from './b2b-api.service';
import { SspaLoggerService } from './sspa-logger.service';
import { Location } from '@angular/common';
import { NgbDatepickerConfig, NgbDateParserFormatter, NgbDateAdapter, NgbDate, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateNorthpowerParserFormatter, NgbDateNorthpowerAdapter } from "./ngb-date-northpower"
import * as moment from 'moment-timezone';
import { VersionInfoService } from './version-info.service';

import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-root',
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateNorthpowerParserFormatter},
    {provide: NgbDateAdapter, useClass: NgbDateNorthpowerAdapter},
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'sspa';
  public env = environment;
  public isLoggedIn: Observable<Boolean> = null;
  public loggedInStatusKnown = false;
  public sspaReturnUrl = '';
  public thisYear: string;
  public versionHash: string;


  constructor(private sspaLogger: SspaLoggerService, private b2bApiService: B2BAPIService, private location: Location, public versionInfoService: VersionInfoService) {
    const now = new moment();
    this.thisYear = now.format('Y');
    const versionInfo = versionInfoService.getVersionInfo();
    this.versionHash = versionInfo.hash;
  }

  ngOnInit() {
    this.sspaLogger.debug('AppComponent ngOnInit');
    this.loggedInStatusKnown = false;
    this.isLoggedIn = this.b2bApiService.getIsLoggedIn().pipe(
      catchError(err => {
        this.sspaLogger.debug('AppComponent ngOnInit pipe map caught error', err);
        return of(false);
      }),
      map(loggedInStatus => {
        this.sspaLogger.debug('AppComponent ngOnInit pipe map loggedInStatus', loggedInStatus ? 'true' : 'false');
        this.loggedInStatusKnown = true;

        //
        // This seems a good place to initialise Sentry so it knows the user's details.
        //
        Sentry.configureScope((scope) => {
          scope.setUser({
            'username': this.b2bApiService.loggedInUserName,
            'email': this.b2bApiService.loggedInEmailAddress,
          });
        });

        return loggedInStatus;
      }),
    );

    this.sspaReturnUrl = encodeURIComponent(btoa(document.location.href));
  }

}