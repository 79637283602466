import { Component, OnInit, Input } from '@angular/core';
import { VoicePortStatus } from '../port-status-list';

@Component({
  selector: 'app-voice-port-status-badge',
  templateUrl: './voice-port-status-badge.component.html',
  styleUrls: ['./voice-port-status-badge.component.scss']
})
export class VoicePortStatusBadgeComponent implements OnInit {

  @Input() set status(theStatus: VoicePortStatus) {
    this._status = theStatus;
    this.updateFromStatus();
  }

  public _status: VoicePortStatus;
  public badgeTitle: string = '';


  public provisioningStatus: string;
  public serviceStatus: string;

  constructor() {}

  ngOnInit() {
    this.updateFromStatus();
  }

  updateFromStatus() {
    this.badgeTitle = this._status.replace(/-/g, ' ');

    switch (this._status)
    {
      case VoicePortStatus.Provisioned:
        this.provisioningStatus = 'port-provisioned';
        this.serviceStatus      = 'registered';
        break;
      case VoicePortStatus.ProvisionedNotConnected:
        this.provisioningStatus = 'port-provisioned';
        this.serviceStatus      = 'registration-failed';
        break;
      case VoicePortStatus.NotYourService:
        this.provisioningStatus = 'provisioned-to-another-provider';
        this.serviceStatus      = '';
        break;
      case VoicePortStatus.NotProvisioned:
        this.provisioningStatus = 'not-provisioned';
        this.serviceStatus      = '';
        break;
      case VoicePortStatus.Loading:
        this.provisioningStatus = '';
        this.serviceStatus      = '';
        break;
      case VoicePortStatus.NotTestable:
        this.provisioningStatus = 'not-testable';
        this.serviceStatus      = '';
        break;
    }

  }
}
