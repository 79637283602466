import { Component, OnInit, Input } from '@angular/core';
// import { Observable } from 'rxjs';
// import { SspaLoggerService } from '../sspa-logger.service';

@Component({
  selector: 'app-fault-status-badge',
  templateUrl: './fault-status-badge.component.html',
  styleUrls: ['./fault-status-badge.component.scss']
})
export class FaultStatusBadgeComponent implements OnInit {

  _faultStatus: string; // In Progress, Restored, Closed, Cancelled
  statusClass: string;  // in-progress, restored, closed, cancelled

  @Input() set faultStatus(value: string) {
      this._faultStatus = value;
      this.statusClass = value.toLowerCase().replace(/ /g, '-');
  }
  get faultStatus(): string {
    return this._faultStatus;
  }

  constructor() { }

  ngOnInit() {
  }

}
