import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { SspaLoggerService } from '../sspa-logger.service';
import { B2BAPIService } from '../b2b-api.service';

@Component({
  selector: 'app-out-of-service-onts',
  templateUrl: './out-of-service-onts.component.html',
  styleUrls: ['./out-of-service-onts.component.scss']
})
export class OutOfServiceOntsComponent implements OnInit {

  public outOfServiceONTs: Observable<any[]>;

  constructor(public b2bApi: B2BAPIService, private sspaLogger: SspaLoggerService, private router: Router) { }

  ngOnInit() {
    this.outOfServiceONTs = this.b2bApi.getOutOfServiceONTs().pipe(
      tap((theOutOfServiceONTs) => {
        console.log('outOfServiceONTs pipe map', theOutOfServiceONTs);
      }),
    )
  }

  onONTTableRowClick(clickedONT) {
    if (clickedONT.fslInstanceIdentifier) {
      this.router.navigate(['/ont_diagnostics'], { queryParams: { fsl_instance: clickedONT.fslInstanceIdentifier } });    
    }
  }
}
