import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, pipe, empty, of } from 'rxjs';
import { map, shareReplay, catchError, switchMapTo, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { ONTStatusJson } from './ont-status-class';
import { ONTFaultJson } from './ont-fault-class';
import { SspaLoggerService } from './sspa-logger.service';
import * as moment from 'moment-timezone';

import { webSocket } from "rxjs/webSocket";
import { throwError } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class B2BAPIService {

  public env = environment;
  // private queryServicesCache: Observable<Array<any>>;
  private _loggedInUserName: string = '';
  private _loggedInEmailAddress: string = '';

  private getONTDiagnosticsRefreshSubject: BehaviorSubject<string> = null;
  private getONTStateCurrentRefreshSubject: BehaviorSubject<string> = null;
  private getONTStateHistoryDailyRefreshSubject: BehaviorSubject<string> = null;

  constructor(private httpClient: HttpClient, private sspaLogger: SspaLoggerService) { }

  // isLoggedIn(): Boolean {
  //   let isLoggedInObservable = this.getIsLoggedIn();
  //   isLoggedInObservable.subscribe(loggedIn => {
  //     // this.sspaLogger.debug('B2BAPIService isLoggedInObservable subscribe function got:', loggedIn);
  //   });
  //   return true;
  // }

  get loggedInUserName(): string {
    return this._loggedInUserName;
  }
  
  get loggedInEmailAddress(): string {
    return this._loggedInEmailAddress;
  }
  
  public getIsLoggedIn(): Observable<Boolean> {

    const httpOptions = {
      headers: new HttpHeaders({
        // 'Cookie':  'foo=bar', // Mick Temp: Not allowed by chrome
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    return this.getLoggedInUserDetails()
      .pipe(
        map(user_details_object => {
          this._loggedInUserName = user_details_object.ee_username;
          this._loggedInEmailAddress = user_details_object.ee_email;
          return user_details_object.logged_in;
        }),
      );
  }

  public getLoggedInUserDetails(): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

// if (false)
// {
//   let fakeError: Error = new Error('This is another SSPA Fake Error');
//   this.sspaLogger.debug('gonna throwError(fakeError)', fakeError);
//   throwError(fakeError);
//   return;
// }

    return this.httpClient
      .get<any>(this.env.b2bapi_url + '/ssp/auth', httpOptions)
      .pipe(
        map(user_details_object => {
          // this.sspaLogger.debug('B2BAPIService::getLoggedInUserDetails', user_details_object);
          return user_details_object;
        }),
      );
  }

  public getFaultsList(getParams: any): Observable<Object[]> {
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/faults', {withCredentials: true, params: getParams})
      .pipe(
        map(faultsResponse => {
            //
            // From b2bapi faults web service call
            // we expect success object of the form {QueryFaultsResponse: {FaultReports: {FaultReport: [...array...]}}}
            // we expect error object of the form {Errors: {errorDescription: "whatever"}}
            //
            return this.mapB2BResponse(faultsResponse, ['QueryFaultsResponse', 'FaultReports', 'FaultReport'], 'getQueryServices');
          },
        ),
        shareReplay(1),
      );
  }

  public getQueryServices(getParams: any): Observable<Object[]> {
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/query_services', {withCredentials: true, params: getParams})
      .pipe(
        map(b2bResponse => {
            //
            // From b2bapi web service call
            // we expect success object of the form {ONTServices: [ ... array of services ... ]}
            // we expect error object of the form {Errors: {errorDescription: "whatever"}}
            //
            // this.sspaLogger.debug('getQueryServices returning b2bResponse', b2bResponse);
            return this.mapB2BResponse(b2bResponse, [], 'getQueryServices');
          },
        ),
        shareReplay(1),
      );
  }

  public getQueryPlace(getParams: any): Observable<Object[]> {
    this.sspaLogger.debug('B2BAPIService getQueryPlace');
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/query_place', {withCredentials: true, params: getParams})
      .pipe(
        map(b2bResponse => {
            return this.mapB2BResponse(b2bResponse, [], 'getQueryPlace');
          },
        ),
        shareReplay(1),
      );
  }

  public getQueryFaults(fsl_instance_id: string): Observable<Object[]> {
    this.sspaLogger.debug('B2BAPIService getQueryFaults');
    const getParams = {
      'fsl_instance_id': fsl_instance_id,
    };
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/query_faults', {withCredentials: true, params: getParams})
      .pipe(
        map(b2bResponse => {
            return this.mapB2BResponse(b2bResponse, [], 'getQueryFaults');
          },
        ),
        shareReplay(1),
      );
  }

  public getONTDiagnosticsWithRefresh(getParams: any): Observable<Object[]>
  {
    if (!this.getONTDiagnosticsRefreshSubject)
    {
      this.getONTDiagnosticsRefreshSubject = new BehaviorSubject<string>('');
    }
    return this.getONTDiagnosticsRefreshSubject.pipe(
      switchMapTo(this.getONTDiagnostics(getParams)),
      shareReplay(1),
    );
  }

  private getONTDiagnostics(getParams: any): Observable<Object[]> {
    this.sspaLogger.debug('B2BAPIService getONTDiagnostics');
// let fakeError: Error = new Error('Fake getONTDiagnostics error');
// this.sspaLogger.debug('getONTDiagnostics gonna throwError(fakeError)', fakeError);
// return throwError(fakeError);

    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/ont_diagnostics', {withCredentials: true, params: getParams})
      .pipe(
        map(b2bResponse => {
            // this.sspaLogger.debug('getONTDiagnostics pipe map calling mapB2BResponse with b2bResponse', b2bResponse);
            return this.mapB2BResponse(b2bResponse, [], 'getONTDiagnostics');
          },
        ),
      );
  }

  public refreshONTDiagnostics()
  {
    this.sspaLogger.debug('B2BAPIService refreshONTDiagnostics');
    this.getONTDiagnosticsRefreshSubject.next('refresh-ont-diagnostics');
  }

  public getONTStateCurrentWithRefresh(getParams: any): Observable<ONTStatusJson>
  {
    if (!this.getONTStateCurrentRefreshSubject)
    {
      this.getONTStateCurrentRefreshSubject = new BehaviorSubject<string>('');
    }
    return this.getONTStateCurrentRefreshSubject.pipe(
      switchMapTo(this.getONTStateCurrent(getParams)),
      shareReplay(1),
    );
  }

  private getONTStateCurrent(getParams: any): Observable<ONTStatusJson> {
    this.sspaLogger.debug('B2BAPIService getONTStateCurrent');
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/ont_state/current', {withCredentials: true, params: getParams})
      .pipe(
        map(b2bResponse => {
            // this.sspaLogger.debug('INNN getONTStateCurrent in get pipe map with b2bResponse', b2bResponse);
            return this.mapB2BResponse(b2bResponse, ['ontStateCurrent'], 'getONTStateCurrent');
          },
        ),
      );
  }

  public refreshONTStateCurrent()
  {
    this.sspaLogger.debug('B2BAPIService refreshONTStateCurrent');
    this.getONTStateCurrentRefreshSubject.next('refresh-ont-state-current');
  }

  public getONTStateHistoryDailyWithRefresh(getParams: any): Observable<ONTStatusJson[]> {
    if (!this.getONTStateHistoryDailyRefreshSubject)
    {
      this.getONTStateHistoryDailyRefreshSubject = new BehaviorSubject<string>('');
    }
    return this.getONTStateHistoryDailyRefreshSubject.pipe(
      switchMapTo(this.getONTStateHistoryDaily(getParams)),
      shareReplay(1),
    );
  }

  private getONTStateHistoryDaily(getParams: any): Observable<ONTStatusJson[]> {
    this.sspaLogger.debug('B2BAPIService getONTStateHistoryDaily');
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/ont_state/history_daily', {withCredentials: true, params: getParams})
      .pipe(
        map(b2bResponse => {
            // this.sspaLogger.debug('INN2 getONTStateHistoryDaily in get pipe map with b2bResponse', b2bResponse);
            return this.mapB2BResponse(b2bResponse, ['ontStateHistory'], 'getONTStateHistoryDaily');
          },
        ),
      );
  }

  public refreshONTStateHistoryDaily()
  {
    this.sspaLogger.debug('B2BAPIService refreshONTStateHistoryDaily');
    this.getONTStateHistoryDailyRefreshSubject.next('refresh-ont-state-history-daily');
  }

  public getONTStateHistoryHourly(getParams: any): Observable<ONTStatusJson[]> {
    this.sspaLogger.debug('B2BAPIService getONTStateHistoryHourly');
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/ont_state/history_hourly', {withCredentials: true, params: getParams})
      .pipe(
        map(b2bResponse => {
            return this.mapB2BResponse(b2bResponse, ['ontStateHistory'], 'getONTStateHistoryHourly');
          },
        ),
        shareReplay(1),
      );
  }

  public getONTStateHistoryAllInHour(getParams: any): Observable<ONTStatusJson[]> {
    this.sspaLogger.debug('B2BAPIService getONTStateHistoryAllInHour');
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/ont_state/history_all_in_hour', {withCredentials: true, params: getParams})
      .pipe(
        map(b2bResponse => {
            return this.mapB2BResponse(b2bResponse, ['ontStateHistory'], 'getONTStateHistoryAllInHour');
          },
        ),
        shareReplay(1),
      );
  }

  public getOutOfServiceONTs(): Observable<any> {
    return this.httpClient
      .get<any>(environment.b2bapi_url + '/b2b_json/no_service_onts_report', {withCredentials: true, params: {}})
      .pipe(
        map(b2bResponse => {
            return this.mapB2BResponse(b2bResponse, [], 'getOutOfServiceONTs');
          },
        ),
        shareReplay(1),
      );
  }

  public getONTFaultHistory(fslInstanceId: string): Observable<ONTFaultJson[]> {
    return this.getQueryFaults(fslInstanceId).pipe(
      map((faultList: any) => {
        // Expect faultList e.g. like this:
        // faults: [
        //   {
        //     b2bFaultRefNum: '111812',
        //     accessSeekerFaultRef: 'sla-test-mick',
        //     FSLID: '00233628WLFC651',
        //     serviceIdentifier: 'FSID00035238',
        //     circuitIdentifier: 'FCID00138775 (Data)',
        //     faultType: 'Intermittent Connection',
        //     status: 'Escalated',
        //     isInProgress: true,
        //     shortAddress: '1 Riverside Drive\nRiverside\nWhangarei',
        //     raisedAt: '2020-10-06T13:57:00+13:00',
        //     occurredAt: '2020-10-06T13:56:00+13:00',
        //     restoredAt: null,
        //     description: 'SLA Test by Mick changed',
        //     emergencyFault: false,
        //     emergencyFaultReason: null,
        //     criticalResponseRequested: false,
        //     retailerContactName: 'jam 1.2.0',
        //     retailerContactPhone: '1234',
        //     retailerContactEmail: 'ian@jamdigital.co.nz',
        //     customerName: 'Mick Buckley changed',
        //     customerPhone1: '09434028899',
        //     customerPhone2: '09434028899',
        //     siteContactName: 'Mick Buckley changgeeeeddd',
        //     siteContactPhone: '0224340288999',
        //     selfIsolation: false,
        //     address: null,
        //     restorationNoteSymptom: 'close with sub find',
        //     restorationNoteProblem: "Customer's Contractor Damage",
        //     restorationNoteAction: 'close with sub do',
        //     onSiteAt: '2021-02-02T00:30:00+13:00'
        //   },
        //   ...
        // ]
        //
        // console.log('getONTFaultHistory', {faultList});
        let result: ONTFaultJson[] = [];
        if (faultList.faults) {
          result = faultList.faults.map((thisFault) => {
            const mappedFault: ONTFaultJson = {
              'faultType': thisFault.faultType,
              'b2bReference': thisFault.b2bFaultRefNum,
              'rspReference': thisFault.accessSeekerFaultRef,
              'faultStatus': thisFault.status,
              'faultRaisedDate': thisFault.raisedAt,
              'serviceRestoredDate': thisFault.restoredAt,
              'faultDescription': thisFault.description,
              'faultSummary': '',
              'faultSymptom': thisFault.restorationNoteSymptom,
              'faultCause': thisFault.restorationNoteProblem,
              'faultAction': thisFault.restorationNoteAction,
              'shortAddress': thisFault.shortAddress,
              'isInProgress': thisFault.isInProgress,
            };
            return mappedFault;
          });
        }
        return result;
      }),
    );
  }

  public getTrafficHeaders(getParams: any): Subject<any> {
    this.sspaLogger.debug('B2BAPIService getTrafficHeaders');
    const b2bapi_ws_url = environment.b2bapi_url.replace('http', 'ws');
    const trafficHeadersUrl = b2bapi_ws_url + '/b2b_ws/traffic_headers';
    // this.sspaLogger.debug('B2BAPIService getTrafficHeaders trafficHeadersUrl', trafficHeadersUrl);
    const subject = webSocket(trafficHeadersUrl);
    // this.sspaLogger.debug('B2BAPIService getTrafficHeaders subject', subject);

    subject.next('start');

    return subject;
  }

  public postNewFaultRequest(postParams: any): Observable<any> {
    this.sspaLogger.debug('B2BAPIService postNewFaultRequest postParams', postParams);
    const occurredString = postParams.dateOccurred + ' ' + postParams.timeOccurred;
    const occurredMoment = new moment(occurredString, 'DD/MM/YYYY hh:mm A');
    postParams.occurredAt = occurredMoment.format(); // ISO 8601
    this.sspaLogger.debug('postParams.occurredAt', postParams.occurredAt);

    if (!occurredMoment.isValid())
    {
      this.sspaLogger.error('There was a problem with your fault date', postParams.dateOccurred + ' ' + postParams.timeOccurred);
      return of({});
    }

    return this.httpClient
      .post<any>(environment.b2bapi_url + '/b2b_json/new_fault', postParams, {withCredentials: true})
      .pipe(
        map(b2bResponse => {
            this.sspaLogger.debug('B2BAPIService postNewFaultRequest in get pipe map with b2bResponse', b2bResponse);
            return b2bResponse;
          },
        ),
      );
  }


  private mapB2BResponse(b2bResponse, payloadFieldArray: Array<string>, caller: string)
  {
    //
    // Handle error response from b2bapi endpoint and extract payload from response object.
    // You can pass an array of payload fields and this function will descend the b2bResponse object field by field.
    //
    // console.log('B2BAPIService::' + caller, b2bResponse);
    if (b2bResponse == 'SspaHttpError')
    {
      this.sspaLogger.debug(caller + ' got SspaHttpError from server'); // Error was handled in HttpInterceptor
      return [];
    }
    else if (!b2bResponse)
    {
      this.sspaLogger.error(caller + ' got no response from server');
      return [];
    }
    else if (b2bResponse.Errors)
    {
      this.sspaLogger.error('Problem with server response', caller + ': ' + b2bResponse.Errors.errorDescription);
      return [];
    }
    let payload = b2bResponse;
    for (const thisField of payloadFieldArray)
    {
      if (!payload[thisField])
      {
        // console.log('B2BAPIService::' + caller + ' no descent BY ' + thisField);
        // We could not descend the b2bResponse object as requested.
        this.sspaLogger.error('Some data was missing in server response', caller + ': ' + JSON.stringify(payloadFieldArray));
        return [];
      }
      // console.log('B2BAPIService::' + caller + ' descending by ' + thisField);
      payload = payload[thisField];
    }
    //
    // Mick Temp: ToDo: This function can return []. Check this is appropriate following errors. Better to use null? or {}? or empty()?
    return payload ? payload : [];
  }


}









