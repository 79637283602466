import * as moment from 'moment-timezone';

import { ONTStatusClass, ONTStatusJson } from './ont-status-class';

export class OntHistoryEvent {

  private _intervalEndMoment: moment.Moment;

  public event: ONTStatusClass;
  public intervalStart: string;
  public intervalEnd: string;

  constructor(theStatusJson: ONTStatusJson)
  {
    if (!theStatusJson.intervalStart)
    {
      theStatusJson.intervalStart = theStatusJson.ontStatusDate;
    }
    this.event = new ONTStatusClass(theStatusJson);
    this.intervalStart = this.event.intervalStartMoment.format(); // ISO 8601
  }

  get intervalStartMoment(): moment {
    return this.event.intervalStartMoment;
  }

  set intervalEndMoment(endMoment: moment) {
    this._intervalEndMoment = moment(endMoment);
    this.intervalEnd = this._intervalEndMoment.format(); // ISO 8601
  }

  get intervalEndMoment(): moment {
    return this._intervalEndMoment;
  }

  get eventDuration(): number {
    //
    // Returns an indication of the duration of this event in (approximately) 10 minute intervals.
    //
    if (!this._intervalEndMoment || !this.event)
    {
      return 0;
    }
    const theDuration = this._intervalEndMoment.diff(this.event.intervalStartMoment, 'minutes');
    let fiveMinuteIntervals = Math.floor(theDuration / 5);
    if (fiveMinuteIntervals == 0)
    {
      fiveMinuteIntervals = 1; // 0 - 9 minutes still counts as one interval
    }
    return fiveMinuteIntervals;
  }
}
