import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SafePipe } from './safe.pipe';
import { VarDebugComponent } from './var-debug/var-debug.component';
import { FaultsReportComponent } from './faults-report/faults-report.component';
import { FaultsQueryComponent } from './faults-query/faults-query.component';
import { FaultsMainComponent } from './faults-main/faults-main.component';

import { SspaHttpInterceptor } from './sspa-http.interceptor';
import { SspaErrorHandlerService } from './sspa-error-handler.service';

import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FaultsSearchComponent } from './faults-search/faults-search.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OntDiagnosticsComponent } from './ont-diagnostics/ont-diagnostics.component';
import { Rj45IconComponent } from './rj45-icon/rj45-icon.component';
import { Rj11IconComponent } from './rj11-icon/rj11-icon.component';
import { PortSelectorComponent } from './port-selector/port-selector.component';
import { PortConfigurationInformationComponent } from './port-configuration-information/port-configuration-information.component';
import { PortDiagnosticsComponent } from './port-diagnostics/port-diagnostics.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { IconSymbolComponent } from './icon-symbol/icon-symbol.component';
import { DataPortStatusBadgeComponent } from './data-port-status-badge/data-port-status-badge.component';
import { VoicePortStatusBadgeComponent } from './voice-port-status-badge/voice-port-status-badge.component';
import { PortStatusPipe } from './port-status.pipe';
import { ONTStatusBadgeComponent } from './ont-status-badge/ont-status-badge.component';
import { ONTStatusPipe } from './ont-status.pipe';
import { OntDailyHistoryComponent } from './ont-daily-history/ont-daily-history.component';
import { OntHourlyHistoryComponent } from './ont-hourly-history/ont-hourly-history.component';
import { OntHourlyHistoryDetailsComponent } from './ont-hourly-history-details/ont-hourly-history-details.component';
import { Nl2brPipe } from './nl2br.pipe';
import { EnIconComponent } from './en-icon/en-icon.component';
import { OntDiagnosisBadgeComponent } from './ont-diagnosis-badge/ont-diagnosis-badge.component';
import { OntModelDetailsComponent } from './ont-model-details/ont-model-details.component';
import { FaultSubmittedComponent } from './fault-submitted/fault-submitted.component';
import { SspaToastContainerComponent } from './sspa-toast-container/sspa-toast-container.component';
import { PortStatusKeyIconComponent } from './port-status-key-icon/port-status-key-icon.component';
import { StatusToLabelPipe } from './status-to-label.pipe';
import { OntHistorySummaryComponent } from './ont-history-summary/ont-history-summary.component';
import { FaultStatusBadgeComponent } from './fault-status-badge/fault-status-badge.component';
import { OutOfServiceOntsComponent } from './out-of-service-onts/out-of-service-onts.component';

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    VarDebugComponent,
    FaultsReportComponent,
    FaultsQueryComponent,
    FaultsMainComponent,
    FaultsSearchComponent,
    OntDiagnosticsComponent,
    Rj45IconComponent,
    Rj11IconComponent,
    PortSelectorComponent,
    PortConfigurationInformationComponent,
    PortDiagnosticsComponent,
    IconButtonComponent,
    IconSymbolComponent,
    DataPortStatusBadgeComponent,
    VoicePortStatusBadgeComponent,
    PortStatusPipe,
    ONTStatusBadgeComponent,
    ONTStatusPipe,
    OntDailyHistoryComponent,
    OntHourlyHistoryComponent,
    OntHourlyHistoryDetailsComponent,
    Nl2brPipe,
    EnIconComponent,
    OntDiagnosisBadgeComponent,
    OntModelDetailsComponent,
    FaultSubmittedComponent,
    SspaToastContainerComponent,
    PortStatusKeyIconComponent,
    StatusToLabelPipe,
    OntHistorySummaryComponent,
    FaultStatusBadgeComponent,
    OutOfServiceOntsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SspaHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SspaErrorHandlerService },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
