import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, FormBuilder } from '@angular/forms';
import { Subscription, NextObserver } from 'rxjs';
import * as moment from 'moment-timezone';

function faultIdValidator (control: FormControl)
{
  let validationError = null;
  if (control.value === undefined || control.value.length == 0)
  {
    validationError = null;
  }
  else if (control.value.length != 6)
  {
    validationError = { 'faultId': {notSixCharacters: true} };
  }
  // console.log('faultIdValidator', validationError);
  return validationError;
};

@Component({
  selector: 'app-faults-search',
  templateUrl: './faults-search.component.html',
  styleUrls: ['./faults-search.component.scss']
})
export class FaultsSearchComponent implements OnInit {

  @Input() observer: NextObserver<any>;
  @Output() searchEventEmitter: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public valueChangesSubscription: Subscription;
  public dateRangeLabel: string = null;

  constructor(fb: FormBuilder)
  {
    this.form = fb.group({
      'service_provider_ref':[''],
      'fault_id':['', faultIdValidator],
      'street_name':[''],
      'fsl':[''],
      'fault_type':[''],
      'fault_status':[''],
      'date_range_dropdown':[''],
      'date_from':[{value: '', disabled: true}],
      'date_to':[{value: '', disabled: true}],
    });

  }

  ngOnInit()
  {
    // console.log('FaultsSearchComponent ngOnInit');
    this.valueChangesSubscription = this.form.valueChanges.subscribe(this.observer);

    //
    // Initialise search filters with any locally stored values
    //
    let faultSearchObject: Object = null;
    const faultSearchString = localStorage.getItem('faultSearch');
    if (faultSearchString != '')
    {
      faultSearchObject = JSON.parse(faultSearchString);
      // console.log('faultSearchObject',faultSearchObject);
    }
    if (faultSearchObject)
    {
      this.form.setValue(faultSearchObject);
    }

    if (!this.formIsEmpty())
    {
      //
      // We have a stored search so submit the fault search form
      //
      this.onSubmit();
    }
  }

  // Good pattern for type-ahead forms
  ngOnDestroy()
  {
    this.valueChangesSubscription.unsubscribe();
  }

  onFaultTypeChange(event)
  {
    this.onSubmit();
  }
  
  onFaultStatusChange(event)
  {
    this.onSubmit();
  }
  
  onDateRangeChange(event)
  {
    let start, end: moment | null;
    const theDateRange = this.form.get('date_range_dropdown').value;
    // console.log('date range value',theDateRange);
    // console.log('local date',new Date());
    switch (theDateRange)
    {
      case 'Today':
        start = moment().startOf('day');
        end   = moment().endOf('day');
        break;
      case 'Week':
        start = moment().startOf('day').add(-1, 'week');
        end   = moment().endOf('day');
        break;
      case 'Month':
        start = moment().startOf('day').add(-1, 'month');
        end   = moment().endOf('day');
        break;
      case 'Year':
        start = moment().startOf('day').add(-1, 'year');
        end   = moment().endOf('day');
        break;
      case '': // Fall through
      default:
        start = end = null;
        break;
    }
    if (start == null)
    {
      this.form.controls['date_from'].setValue('')
      this.form.controls['date_to'].setValue('');
      this.dateRangeLabel = null;
    }
    else
    {
      this.form.controls['date_from'].setValue(start.toISOString()); // UTC format
      this.form.controls['date_to'].setValue(end.toISOString()); // UTC format
      if (theDateRange == 'Today')
      {
        this.dateRangeLabel = start.format('DD MMM YYYY');
      }
      else
      {
        this.dateRangeLabel = start.format('DD MMM YYYY') + ' to ' + end.format('DD MMM YYYY');
      }
    }
    this.onSubmit();
  }
  
  onSubmit()
  {
    this.searchEventEmitter.emit(this.form.getRawValue()); // also gets values of disabled components

    localStorage.setItem('faultSearch', JSON.stringify(this.form.getRawValue()));
  }

  formIsEmpty()
  {
    let empty = true;
    // console.log('formIsEmpty');
    Object.keys(this.form.controls).forEach(key => {
      const val = this.form.controls[key].value;
      console.log('key, val:', key, val);
      if (val)
      {
        empty = false;
      }
    });
    // console.log('formIsEmpty returns', empty);
    return empty;
  }
}
