import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaultsReportComponent } from './faults-report/faults-report.component';
import { FaultsQueryComponent } from './faults-query/faults-query.component';
import { FaultsMainComponent } from './faults-main/faults-main.component';
import { OntDiagnosticsComponent } from './ont-diagnostics/ont-diagnostics.component';
import { OntModelDetailsComponent } from './ont-model-details/ont-model-details.component';
import { FaultSubmittedComponent } from './fault-submitted/fault-submitted.component';
import { OutOfServiceOntsComponent } from './out-of-service-onts/out-of-service-onts.component';


const routes: Routes = [
    {path: '', redirectTo: 'faults_main', pathMatch: 'full'},
    {path: 'faults_main', component: FaultsMainComponent},
    {path: 'faults_report', component: FaultsReportComponent},
    {path: 'faults_query', component: FaultsQueryComponent},
    {path: 'fault_submitted', component: FaultSubmittedComponent},
    {path: 'ont_diagnostics', component: OntDiagnosticsComponent},
    {path: 'ont_model_details', component: OntModelDetailsComponent},
    {path: 'out_of_service_onts', component: OutOfServiceOntsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
