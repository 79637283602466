import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SspaLoggerService } from './sspa-logger.service';

@Injectable()
export class SspaHttpInterceptor implements HttpInterceptor {

  constructor(private sspaLogger: SspaLoggerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // this.sspaLogger.debug('SspaHttpInterceptor-->> (ok)', event);
          //
          // Testing
          //
          let fakeError: HttpErrorResponse = new HttpErrorResponse({error: { error: 'SSPA Fake Error', message: 'Just another SSPA Fake Error' }, headers: event.headers, status: 404, statusText: 'This is a Fake Error', url: event.url});
          if (event.body && event.body.ONTServices        && false) { throw fakeError; }
          if (event.body && event.body.queryPlaceResponse && false) { throw fakeError; }
          if (event.body && event.body.ontStateCurrent    && false) { throw fakeError; }
          if (event.body && event.body.diagnosticsResult  && false) { throw fakeError; }
          if (event.body && event.body.ontStateHistory    && false) { throw fakeError; }
          //
          // End Testing
          //
        }
        // this.sspaLogger.debug('SspaHttpInterceptor returns');
        return event;
      }),
      catchError((error: any) => {
        // this.sspaLogger.error_no_log('SspaHttpInterceptor catchError error', error);

        let errorHeading: string = 'There was a problem loading data.';
        let errorDetail: string = '';

        if (error.dataError || error.systemError)
        {
          errorDetail = (error.dataError ? 'Data' : 'System') + ' Error: ' + error.message;
        }
        else if (error.error)
        {
          errorDetail = (error.error && error.error.message) ? 'Error Message: ' + error.error.message : 'Message: ' + error.message;

        }
        this.sspaLogger.error_no_log(errorHeading, errorDetail);

        //
        // Fault has been handled here in the interceptor. Just tell the caller a fault happened
        // but don't pass back any fault information.
        //
        const sspaErrorEvent: HttpResponse<string> = new HttpResponse({body: 'SspaHttpError'});
        return of(sspaErrorEvent);
    }));

  }
}



