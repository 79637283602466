import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-symbol',
  templateUrl: './icon-symbol.component.html',
  styleUrls: ['./icon-symbol.component.scss']
})
export class IconSymbolComponent implements OnInit {

  @Input() symbol: String;
  @Input() symbolClass: String;
  @Input() svgClass: String;

  constructor() { }

  ngOnInit() {
  }

}

