import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { B2BAPIService } from '../b2b-api.service';
import { Observable, NextObserver } from 'rxjs';
import { EnIconComponent } from '../en-icon/en-icon.component';

@Component({
  selector: 'app-faults-query',
  templateUrl: './faults-query.component.html',
  styleUrls: ['./faults-query.component.scss']
})
export class FaultsQueryComponent implements OnInit {

  public env = environment;
  public faultsList: Observable<Object[]>;
  public observer: NextObserver<any>;
  public faultsListLoading: Boolean = false;

  constructor(public b2bApiService: B2BAPIService) {}

  ngOnInit() {
    this.observer = {
      next(formValues) {
        // //
        // // consume form value changes. Could e.g. update faults list after every change.
        // //
        // console.log('faults query component observer next', formValues);
      }
    }
    // this.faultsList = this.faultsListService.getFaultsList({});
  }

  onSearchEvent(evt) {
    // console.log('FaultsQueryComponent onSearchEvent', evt);
    this.faultsListLoading = true;
    this.faultsList = this.b2bApiService.getFaultsList(evt);
  }
}
