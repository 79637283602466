import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { ONTStatusClass } from '../ont-status-class';
import { Observable } from 'rxjs';
import { SspaLoggerService } from '../sspa-logger.service';

@Component({
  selector: 'app-ont-history-summary',
  templateUrl: './ont-history-summary.component.html',
  styleUrls: ['./ont-history-summary.component.scss']
})
export class OntHistorySummaryComponent implements OnInit {

  @Input() dailyHistory: ONTStatusClass[];
  @Output() summaryClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private sspaLogger: SspaLoggerService) { }

  ngOnInit() {
  }

  public onSummaryClick(event: any)
  {
    this.summaryClick.next(event);
  }


}

