import { Injectable } from '@angular/core';
import { SspaToastService } from './sspa-toast.service';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class SspaLoggerService {

  //
  // Mick Temp: ToDo Log errors to b2bapi service. Log errors to Sentry. Get a stack trace?
  //
  public env = environment;
  
  constructor(public sspaToastService: SspaToastService, private httpClient: HttpClient) { }

  public debug(message: string, detail: any = '', ...objectsToConsole: any[])
  {
    let messageOutArgs = ['debug', message, detail].concat(objectsToConsole);
    this.message_out.apply(this, messageOutArgs);
  }

  public info(message: string, detail: any = '')
  {
    this.message_out('info', message, detail);
  }

  // public wait(message: string, detail: any = '')
  // {
  //   this.message_out('wait', message, detail);
  // }

  public success(message: string, detail: any = '')
  {
    this.message_out('success', message, detail);
  }

  public warning(message: string, detail: any = '')
  {
    this.message_out('warning', message, detail);
  }

  public warning_no_log(message: string, detail: any = '')
  {
    this.message_out('warning_no_log', message, detail);
  }

  public error(message: string, detail: any = '')
  {
    this.message_out('error', message, detail);
  }

  public error_no_log(message: string, detail: any = '')
  {
    this.message_out('error_no_log', message, detail);
  }

  public error_no_toast(message: string, detail: any = '')
  {
    this.message_out('error_no_toast', message, detail);
  }

  public tell_sentry(message: string)
  {
    this.error_to_sentry('SSPA Tell Sentry', message);
  }

  private error_to_sentry(errorName: string, message: string)
  {
    let errorForSentry = Error(message);
    errorForSentry.name = errorName;
    Sentry.captureException(errorForSentry);
  } 

  private message_out(severity: string, message: string, detail: any, ...objectsToConsole: any[])
  {
    let consolePrefix: string = '';
    let toastPrefix: string = '';
    let showToast: boolean = false;
    let logToServer: boolean = false;
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return '[CIRCULAR]';
          }
          seen.add(value);
        }
        return value;
      };
    };

    let detailString;
    let mappedSeverity = severity;

    if (typeof detail == 'string')
    {
      detailString = detail;
    }
    else
    {
      detailString = JSON.stringify(detail, getCircularReplacer, 2);

    }

    switch (severity)
    {
      case 'debug':
        consolePrefix = 'SSPA Debug:';
        showToast = false;
        logToServer = false;
        break;
      case 'info':
        consolePrefix = 'SSPA Info:';
        showToast = true;
        toastPrefix = '';
        logToServer = false;
        break;
      case 'wait':
        consolePrefix = 'SSPA Wait:';
        showToast = true;
        toastPrefix = 'Wait: ';
        logToServer = false;
        break;
      case 'success':
        consolePrefix = 'SSPA Success:';
        showToast = true;
        toastPrefix = 'Success: ';
        logToServer = false;
        break;
      case 'warning':
        consolePrefix = 'SSPA Warn:';
        showToast = true;
        toastPrefix = 'Warning: ';
        logToServer = true;
        break;
      case 'warning_no_log': // same as warning but don't log to server
        mappedSeverity = 'warning';
        consolePrefix = 'SSPA Warn:';
        showToast = true;
        toastPrefix = 'Warning No Log: ';
        logToServer = false;
        break;
      case 'warning_no_toast': // same as warning but don't pop up a toast
        mappedSeverity = 'warning';
        consolePrefix = 'SSPA Warn:';
        showToast = false;
        toastPrefix = 'Warning No Toast: ';
        logToServer = true;
        break;
      case 'error':
        consolePrefix = 'SSPA Error:';
        showToast = true;
        toastPrefix = 'Error: ';
        logToServer = true;
        break;
      case 'error_no_log': // same as error but don't log to server
        mappedSeverity = 'error';
        consolePrefix = 'SSPA Error No Log:';
        showToast = true;
        toastPrefix = 'Error: ';
        logToServer = false;
        break;
      case 'error_no_toast': // same as error but don't pop up a toast
        mappedSeverity = 'error';
        consolePrefix = 'SSPA Error No Toast:';
        showToast = false;
        toastPrefix = 'Error: ';
        logToServer = true;
        break;
    }

    if (!this.env.production)
    {
      let consoleArgs = [consolePrefix, message, detail].concat(objectsToConsole);

      if (['error'].indexOf(mappedSeverity) > -1)
      {
        console.error.apply(console, consoleArgs);
      }
      else
      {
        console.log.apply(console, consoleArgs);
      }
    }

    if (showToast)
    {
      if (!this.env.production)
      {
        this.sspaToastService.showToast(mappedSeverity, toastPrefix + message, detailString);
      }
      else
      {
        this.sspaToastService.showToast(mappedSeverity, toastPrefix + message);
      }
    }

    if (logToServer)
    {
      let loggerSubscription = this.postLogMessage("\nMessage: " + message + "\nDetails: " + detailString).subscribe(
        logger_reponse => {
        },
        logger_error => {
          console.error('Could not log error message to B2BAPI.', logger_error);
        },
        () => {
          // console.log('Logger POST Complete');
        },
      );
    }

    if (severity == 'error' || severity == 'error_no_log' || severity == 'error_no_toast')
    {
      this.error_to_sentry('SSPA Error', message);
    }
  }

  private postLogMessage(message: string): Observable<Boolean> {
    return this.httpClient
      .post<any>(environment.b2bapi_url + '/logger', message, {withCredentials: true}) 
      .pipe(
        map(logger_response => {
          return true; // Mick Temp: this code doesn't execute after an error
        }),
      );
  }
}













