import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as Sentry from '@sentry/browser';

import { B2BAPIService } from './b2b-api.service';
import { SspaLoggerService } from './sspa-logger.service';
import { environment } from '../environments/environment';

import { VersionInfoService } from './version-info.service';

@Injectable()
export class SspaErrorHandlerService implements ErrorHandler {

  public env = environment;

  constructor(private injector: Injector, public b2bApi: B2BAPIService, public versionInfo: VersionInfoService) {

    const theVersionInfo = versionInfo.getVersionInfo();

    Sentry.init({
      dsn: 'https://1f3422af17e8458092a56d2334da2109@sentry.io/1862672',
      environment: this.env.env,
      release: 'sspa@' + theVersionInfo.hash,
    });


  }

  handleError(error) {
    const sspaLogger = this.injector.get(SspaLoggerService);
    const location = this.injector.get(LocationStrategy);
    // const message = error.message ? error.message : error.toString();
    const message = (error.error && error.error.message) ? error.error.message : error.toString();
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const that = this;
    //
    // Use the SSPA Logger to log this error which was thrown somewhere in the angular app.
    //
    sspaLogger.error_no_toast('SspaErrorHandler: ' + message, url); // logs to server and to Sentry

    // Report error to sentry app in context of current user (if any)
    // Sentry.withScope((scope) => {
    //   scope.setUser({
    //     'username': this.b2bApi.loggedInUserName,
    //     'email': this.b2bApi.loggedInEmailAddress,
    //   });
    //   // scope.setLevel('error');
    //   Sentry.captureException(error.originalError || error);
    // });

    throw error;
  }
  
}