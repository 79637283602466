import { Component, OnInit, Input, HostListener } from '@angular/core';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { IconSymbolComponent } from '../icon-symbol/icon-symbol.component';
import { Observable, Subject, BehaviorSubject, combineLatest, of, empty, zip, throwError } from 'rxjs';
import { map, mergeMap, shareReplay, delay, catchError } from 'rxjs/operators';
import { DataPortStatusBadgeComponent } from '../data-port-status-badge/data-port-status-badge.component';
import { VoicePortStatusBadgeComponent } from '../voice-port-status-badge/voice-port-status-badge.component';
import { environment } from '../../environments/environment';
import { B2BAPIService } from '../b2b-api.service';
import { SspaLoggerService } from '../sspa-logger.service'; // Mick Temp: Not appropriate in presentation component
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-port-diagnostics',
  templateUrl: './port-diagnostics.component.html',
  styleUrls: ['./port-diagnostics.component.scss']
})
export class PortDiagnosticsComponent implements OnInit {

  @Input() portDiagnostics: Observable<Object>;

  public env = environment;
  public exploringTraffic: boolean = false;
  public trafficSubject: Subject<any> = null;
  public trafficHeaders: Observable<any> = null;

  public allTrafficHeaders: Array<object> = [];
  public trafficHeadersSubscription = null;
  
  constructor(public b2bApi: B2BAPIService, private sspaLogger: SspaLoggerService) { } // Mick Temp: Surely we should pass in an observable. Don't want to call services from presentation component

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onStopTraffic();
  }

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {
    // this.sspaLogger.debug('window:beforeunload');
    this.onStopTraffic();
    // if(this.hasChanges)
    // {
    //   $event.returnValue='Your data will be lost!';
    // }
    // else
    // {
    //   $event.returnValue='hasChanges is false!';
    // }
    // $event.returnValue = 'Blahhhhhhh';
  }

  public onExploreTrafficClick(event) {
    this.sspaLogger.debug('onExploreTrafficClick');
    this.exploringTraffic = true;

    this.onStopTraffic();

    this.trafficSubject = this.b2bApi.getTrafficHeaders({});
    this.trafficHeaders = this.trafficSubject.asObservable().pipe(
      catchError((err) => {
      this.sspaLogger.debug('onExploreTrafficClick caught error', err);
        return of('NoData');
      }),
      map(trafficHeaders => {
        return trafficHeaders;
      }),
    );

    this.allTrafficHeaders = []; // reset on repeat observable creation
    this.trafficHeadersSubscription = this.trafficHeaders.subscribe({
      next: (value) => {
        //
        // Expecting e.g.
        // {b2b_ws_contol: 'something', handled: 'whatever'} or
        // {b2b_ws_payload: 'a long string'} or
        //
        //
        this.sspaLogger.debug('trafficHeaders subscribe got value', value);

        if (value.b2b_ws_payload)
        {
          const headerFrame = this.parseRawHeader(value.b2b_ws_payload);
          this.allTrafficHeaders.push(headerFrame);
        }
      },
      error: (err) => {
        this.sspaLogger.debug('trafficHeaders subscribe error', err);
      },
      complete: () => {
        this.sspaLogger.debug('trafficHeaders complete');
      },
    });
  }

  public onStopTraffic() {
    if (this.trafficHeadersSubscription)
    {
      this.sspaLogger.debug('PortDiagnosticsComponent onStopTraffic sending stop to trafficSubject');
      this.trafficSubject.next('stop');
      this.sspaLogger.debug('PortDiagnosticsComponent onStopTraffic unsubscribing trafficHeadersSubscription');
      this.trafficHeadersSubscription.unsubscribe();
    }
  }

  public onStartTraffic() {
    if (this.trafficHeadersSubscription)
    {
      this.sspaLogger.debug('PortDiagnosticsComponent onStartTraffic sending start to trafficSubject');
      this.trafficSubject.next('start');
    }
  }

  public onCloseTrafficOverlay(event) {
    this.onStopTraffic();
    this.exploringTraffic = false;
  }


  private parseRawHeader(rawHeader: string)
  {
    //
    // E.g.
    // 15:11:48.555747 00:23:3e:92:2b:9a > 1c:7f:2c:95:5c:b4, 802.1Q, length 82: vlan 2261, p 0, ethertype 802.1Q, vlan 1432, p 0, ethertype PPPoE S, PPPoE [ses 0x1]IP (0x0021), length 54: 172.217.25.174.443 > 122.61.49.218.34264: tcp 0
    //
    let headerMatch = rawHeader.match(/([0-9]+\:[0-9]+\:[0-9]+\.[0-9]+)/);
    let ipSourceDest = rawHeader.match(/([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+) > ([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)/);
    this.sspaLogger.debug('parseRawHeader headerTime', headerMatch);
    this.sspaLogger.debug('parseRawHeader ipSourceDest', ipSourceDest);

    let headerTime = (headerMatch  && headerMatch.length  == 2) ? headerMatch[1]  : '';
    let ipSource   = (ipSourceDest && ipSourceDest.length == 3) ? ipSourceDest[1] : '';
    let ipDest     = (ipSourceDest && ipSourceDest.length == 3) ? ipSourceDest[2] : '';

    return {
      time: headerTime, 
      source: ipSource,
      destination: ipDest,
      raw: rawHeader
    };
  }
}








