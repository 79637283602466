import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-faults-main',
  templateUrl: './faults-main.component.html',
  styleUrls: ['./faults-main.component.scss']
})
export class FaultsMainComponent implements OnInit {

  public env = environment;
  
  constructor() { }

  ngOnInit() {
  }

}
