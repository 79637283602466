import { Component, OnInit, TemplateRef } from '@angular/core';
import {SspaToastService} from '../sspa-toast.service';

@Component({
  selector: 'sspa-toast-container',
  templateUrl: './sspa-toast-container.component.html',
  styleUrls: ['./sspa-toast-container.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class SspaToastContainerComponent implements OnInit {

  constructor(public sspaToastService: SspaToastService) {}

  ngOnInit() {
  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  public hide(toast) {
    this.sspaToastService.remove(toast);
  }
}

