import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { ONTStatusClass } from '../ont-status-class';
import { Observable } from 'rxjs';
import { SspaLoggerService } from '../sspa-logger.service';

@Component({
  selector: 'app-ont-daily-history',
  templateUrl: './ont-daily-history.component.html',
  styleUrls: ['./ont-daily-history.component.scss']
})
export class OntDailyHistoryComponent implements OnInit {

  @Input() dailyHistory: ONTStatusClass[];
  @Output() selectedDay: EventEmitter<ONTStatusClass> = new EventEmitter<ONTStatusClass>();

  public theSelectedDay: ONTStatusClass = null;

  constructor(private sspaLogger: SspaLoggerService) { }

  ngOnInit() {
  }

  public onDayClick(day: ONTStatusClass)
  {
    this.sspaLogger.debug('onDayClick got day', day);
    this.theSelectedDay = day;
    this.selectedDay.next(day);
  }

}
