import { Component, OnInit, Input } from '@angular/core';
import { ONTStatusClass } from '../ont-status-class';
import { OntHistoryEvent } from '../ont-history-event';
import { Subject, BehaviorSubject } from 'rxjs';
import { map, mergeMap, shareReplay, delay, catchError } from 'rxjs/operators';

import { SspaLoggerService } from '../sspa-logger.service';

@Component({
  selector: 'app-ont-hourly-history-details',
  templateUrl: './ont-hourly-history-details.component.html',
  styleUrls: ['./ont-hourly-history-details.component.scss']
})
export class OntHourlyHistoryDetailsComponent implements OnInit {

  @Input() allInHourEvents: OntHistoryEvent[];

  public selectedEvent: BehaviorSubject<OntHistoryEvent> = new BehaviorSubject(null);
  public eventCount: number = null;

  constructor(private sspaLogger: SspaLoggerService) { }

  ngOnInit() {
    // this.sspaLogger.debug('OntHourlyHistoryDetailsComponent ngOnInit allInHourEvents', this.allInHourEvents);
    this.eventCount = this.allInHourEvents.length;
    this.sspaLogger.debug('OntHourlyHistoryDetailsComponent ngOnInit eventCount', this.eventCount);

    if (this.eventCount == 1)
    {
      this.sspaLogger.debug('OKOK eventCount was 1 clicking onInHourEventClick');
      this.onInHourEventClick(this.allInHourEvents[0]); // if there's only one event then select it
    }
  }

  public onInHourEventClick(event: OntHistoryEvent)
  {
    this.sspaLogger.debug('onInHourEventClick event', event);
    this.selectedEvent.next(event);

  }
}
