import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-fault-submitted',
  templateUrl: './fault-submitted.component.html',
  styleUrls: ['./fault-submitted.component.scss']
})
export class FaultSubmittedComponent implements OnInit {

  public type: string = null;
  public faultId: string = null;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    this.faultId = this.activatedRoute.snapshot.paramMap.get('fault_id');
  }

}
