
export const enum DataPortStatus {
  ProvisionedDeviceDetected    = 'provisioned-device-detected',
  ProvisionedNoDevice          = 'provisioned-no-device',
  ProvisionedDegradedService   = 'provisioned-degraded-service',
  NotYourServiceDeviceDetected = 'not-your-service-device-detected',
  NotYourServiceNoDevice       = 'not-your-service-no-device',
  NotProvisionedDeviceDetected = 'not-provisioned-device-detected',
  NotProvisionedNoDevice       = 'not-provisioned-no-device',
  Loading                      = 'loading',
  NotTestable                  = 'not-testable',
};

export const withProvisioningDataPortStatuses = [
  DataPortStatus.ProvisionedDeviceDetected,
  DataPortStatus.ProvisionedNoDevice,
  DataPortStatus.ProvisionedDegradedService,
  DataPortStatus.NotYourServiceDeviceDetected,
  DataPortStatus.NotYourServiceNoDevice,
];

export const withOwnedServiceDataPortStatuses = [
  DataPortStatus.ProvisionedDeviceDetected,
  DataPortStatus.ProvisionedNoDevice,
  DataPortStatus.ProvisionedDegradedService,
];

export const withDeviceDataPortStatuses = [
  DataPortStatus.ProvisionedDeviceDetected,
  DataPortStatus.NotYourServiceDeviceDetected,
  DataPortStatus.NotProvisionedDeviceDetected,
];

export const enum VoicePortStatus {
  Provisioned             = 'provisioned',
  ProvisionedNotConnected = 'provisioned-not-connected',
  NotYourService          = 'not-your-service',
  NotProvisioned          = 'not-provisioned',
  Loading                 = 'loading',
  NotTestable             = 'not-testable',
};

export const withProvisioningVoicePortStatus = [
  VoicePortStatus.Provisioned,
  VoicePortStatus.ProvisionedNotConnected,
];

export const withOwnedServiceVoicePortStatus = [
  VoicePortStatus.Provisioned,
  VoicePortStatus.ProvisionedNotConnected,
];

export class PortStatusList {
  private d1: DataPortStatus;
  private d2: DataPortStatus;
  private d3: DataPortStatus;
  private d4: DataPortStatus;
  private v1: VoicePortStatus;
  private v2: VoicePortStatus;

  private dataPorts:   Array<string> = ['d1','d2','d3','d4'];
  private voicePorts:  Array<string> = ['v1','v2'];

   constructor(
    d1Status: DataPortStatus = DataPortStatus.Loading,
    d2Status: DataPortStatus = DataPortStatus.Loading,
    d3Status: DataPortStatus = DataPortStatus.Loading,
    d4Status: DataPortStatus = DataPortStatus.Loading,
    v1Status: VoicePortStatus = VoicePortStatus.Loading,
    v2Status: VoicePortStatus = VoicePortStatus.Loading)
   {
       this.d1 = d1Status;
       this.d2 = d2Status;
       this.d3 = d3Status;
       this.d4 = d4Status;
       this.v1 = v1Status;
       this.v2 = v2Status;
   }

   public setDataPortStatus(thePort: string, theStatus: DataPortStatus) {
    if (this.isDataPort(thePort)) {
      this[thePort] = theStatus;
    } else {
      console.error('Invalid ONT port ' + thePort + ' was ignored'); // Mick Temp: throw
    }
  }

   public setVoicePortStatus(thePort: string, theStatus: VoicePortStatus) {
    if (this.isVoicePort(thePort)) {
      this[thePort] = theStatus;
    } else {
      console.error('Invalid ONT port ' + thePort + ' was ignored'); // Mick Temp: throw
    }
  }

  public getDataPortStatus(thePort) : DataPortStatus {
    if (this.isDataPort(thePort)) {
      return this[thePort];
    }
    return null;
  }

  public getVoicePortStatus(thePort) : VoicePortStatus {
    if (this.isVoicePort(thePort)) {
      return this[thePort];
    }
    return null;
  }


  public getPortsWithProvisionedService() : Array<string>
  {
    return this.getDataPortsWithProvisionedService().concat(this.getVoicePortsWithProvisionedService());
  }

  public getDataPortsWithProvisionedService() : Array<string>
  {
    return this.portsInArray('dataPorts', withProvisioningDataPortStatuses);
  }

  public getVoicePortsWithProvisionedService() : Array<string>
  {
    return this.portsInArray('voicePorts', withProvisioningVoicePortStatus);
  }

  public getPortsWithOwnedService() : Array<string>
  {
    return this.getDataPortsWithOwnedService().concat(this.getVoicePortsWithOwnedService());
  }

  public getDataPortsWithOwnedService() : Array<string>
  {
    return this.portsInArray('dataPorts', withOwnedServiceDataPortStatuses);
  }

  public getVoicePortsWithOwnedService() : Array<string>
  {

    return this.portsInArray('voicePorts', withOwnedServiceVoicePortStatus);
  }

  public getPortsWithDeviceDetected() : Array<string>
  {
    return this.getDataPortsWithDeviceDetected().concat(this.getVoicePortsWithDeviceDetected());
  }

  public getDataPortsWithDeviceDetected() : Array<string>
  {
    return this.portsInArray('dataPorts', withDeviceDataPortStatuses);
  }

  public getVoicePortsWithDeviceDetected() : Array<string>
  {
    let result = []; // At the moment our hardware can't detected a device in a voice port.
    return result;
  }


  private isDataPort(thePort: string) {
    return (this.dataPorts.indexOf(thePort) > -1);
  }

  private isVoicePort(thePort: string) {
    return (this.voicePorts.indexOf(thePort) > -1);
  }

  private portsInArray(portsSelector, portsStatusArray)
  {
    //
    // portsSelector is 'dataPorts' or 'voicePorts'
    // portStatusArray is array of port statuses.
    //
    let result = [];
    this[portsSelector].forEach((selectedPort, index) => {
      if (portsStatusArray.indexOf(this[selectedPort]) > -1)
      {
        result.push(selectedPort) // e.g. "d1" or "v1"
      }
    });
    return result;
  }

};
