import { Component, OnInit, Input } from '@angular/core';
import { IconSymbolComponent } from '../icon-symbol/icon-symbol.component';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {

  @Input() symbol: String;
  @Input() buttonClass: String;
  @Input() symbolClass: String;
  @Input() svgClass: String;
  @Input() label: String;

  constructor() { }

  ngOnInit() {
    if (this.svgClass == null || this.svgClass == '')
    {
      this.svgClass = 'size-18 float-right'; // Default svg class. Float improves vertical alignment
    }
  }

}
