import { Component, OnInit, Input } from '@angular/core';
import { ONTDiagnosisClass } from '../ont-diagnosis-class';
import { Observable } from 'rxjs';
import { SspaLoggerService } from '../sspa-logger.service';

@Component({
  selector: 'app-ont-diagnosis-badge',
  templateUrl: './ont-diagnosis-badge.component.html',
  styleUrls: ['./ont-diagnosis-badge.component.scss']
})
export class OntDiagnosisBadgeComponent implements OnInit {

  @Input() diagnosis: ONTDiagnosisClass;

  public haveProblems: boolean;
  public numProblems: number;

  public haveRemarks: boolean;
  public numRemarks: number;

  public badgeClass: string;
  public badgeLabel: string;

  public showRemarks: boolean = false;

  constructor(private sspaLogger: SspaLoggerService) { }

  ngOnInit() {
    this.numProblems = this.diagnosis.getProblems().length;
    this.haveProblems = this.numProblems > 0;
    if (this.haveProblems)
    {
      this.badgeClass = 'problems';
      this.badgeLabel = this.numProblems + ' Problem' + ((this.numProblems == 1) ? '' : 's') + ' Found';
    }
    else
    {
      this.badgeClass = 'no-problem';
      this.badgeLabel = 'No Problems Found';
    }

    this.numRemarks = this.diagnosis.getProblems().length;
    this.haveRemarks = this.numProblems > 0;
  }

  public onShowHideClick(event) {
    this.showRemarks = !this.showRemarks;
  }
}
