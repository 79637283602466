import { Component, OnInit, Input } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';

import { SspaLoggerService } from '../sspa-logger.service';

@Component({
  selector: 'app-port-configuration-information',
  templateUrl: './port-configuration-information.component.html',
  styleUrls: ['./port-configuration-information.component.scss']
})
export class PortConfigurationInformationComponent implements OnInit {

  @Input() portConfigDetails: Observable<Object>;


  constructor(private sspaLogger: SspaLoggerService) { }

  ngOnInit() {
  }

  onLogCircuitFaultClick(fibreServiceId, fibreCircuitId)
  {
    this.sspaLogger.debug('onLogCircuitFaultClick', fibreServiceId, fibreCircuitId);
  }
}
