import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SspaToastService {
  toasts: any[] = [];

  constructor() { }


  public showToast(severity: string, text: string, detail: string = '') {

    // let text_and_detail = text;
    // if (detail)
    // {
    //   text_and_detail = text + ' ' + detail; // Mick Temp: Use a template!
    // }

    switch (severity)
    {
      case 'debug':
        this.showDebug(text, detail);
        break;
      case 'info':
        this.showInfo(text, detail);
        break;
      case 'success':
        this.showSuccess(text, detail);
        break;
      case 'warning':
        this.showWarning(text, detail);
        break;
      case 'error':
        this.showError(text, detail);
        break;
    }
  }

  private showDebug(text: string, detail: string) {
    this.show(text, detail, { classname: 'bg-secondary text-light', delay: 10000 });
  }

  private showInfo(text: string, detail: string) {
    this.show(text, detail, { classname: 'bg-primary text-light', delay: 10000 });
  }

  private showSuccess(text: string, detail: string) {
    this.show(text, detail, { classname: 'bg-success text-light', delay: 10000 });
  }

  private showWarning(text: string, detail: string) {
    this.show(text, detail, { classname: 'bg-warning text-light', delay: 10000 });
  }

  private showError(text: string, detail: string) {
    this.show(text, detail, { classname: 'bg-danger text-light', delay: 15000 });
  }

  public show(textOrTpl: string | TemplateRef<any>, detail: string, options: any = {}) {
    this.toasts.push({ textOrTpl, 'detail': detail, ...options });
  }

  public remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

}

