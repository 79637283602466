import { Component, OnInit, Input } from '@angular/core';
import { DataPortStatus } from '../port-status-list';
import { SspaLoggerService } from '../sspa-logger.service';
import { EnIconComponent } from '../en-icon/en-icon.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rj45-icon',
  templateUrl: './rj45-icon.component.html',
  styleUrls: ['./rj45-icon.component.scss']
})
export class Rj45IconComponent implements OnInit {

  @Input() iconSelected: Observable<boolean>;
  @Input() iconLabel: string;
  @Input() set iconStatus(theIconStatus: DataPortStatus) {
    this._iconStatus = theIconStatus;
    // this.sspaLogger.debug('Rj45IconComponent set iconStatus theIconStatus', theIconStatus);
    this.updateFromIconStatus();
  }
  @Input() iconWidth: Number = 80;
  @Input() iconHeight: Number = 80;
  @Input() decorateIcon: boolean = true; // draw provisioning / service status dot / no-entry sign

  public _iconStatus: DataPortStatus;
  public iconSize: string;
  public iconDisabled: Boolean;
  public iconNotify: Boolean;
  public iconMinus: Boolean;
  public iconNotYours: Boolean;

  public viewBox: string;
  public iconTitle: string = '';
  public iconScale: Number;

  constructor(private sspaLogger: SspaLoggerService) {
    this._iconStatus = DataPortStatus.Loading;

    if (!this.iconLabel)
    {
      this.iconLabel = 'Data';
    }
    // this.sspaLogger.debug('Rj45IconComponent constructor set this._iconStatus', this._iconStatus);
  }

  ngOnInit() {
    // this.sspaLogger.debug('Rj45IconComponent ngOnInit');
    this.updateFromIconStatus();
  }

  updateFromIconStatus() {
    //
    // Use _iconStatus to work out the rest of the icon's variables
    //
    this.viewBox = "-30 -30 60 60";

    this.iconDisabled = false;
    this.iconNotify = false;
    this.iconMinus = false;
    this.iconNotYours = false;

    // this.sspaLogger.debug('Rj45IconComponent updateFromIconStatus this._iconStatus', this._iconStatus);
    switch  (this._iconStatus)
    {
      case DataPortStatus.ProvisionedDeviceDetected:
        this.iconSize = 'large';
        this.iconNotify = true;
        break;
      case DataPortStatus.ProvisionedNoDevice:
        this.iconSize = 'large';
        break;
      case DataPortStatus.ProvisionedDegradedService:
        this.iconSize = 'large';
        this.iconNotify = true;
        break;
      case DataPortStatus.NotYourServiceDeviceDetected:
        this.iconSize = 'large';
        this.iconNotYours = true;
        break;
      case DataPortStatus.NotYourServiceNoDevice:
        this.iconSize = 'large';
        this.iconNotYours = true;
        break;
      case DataPortStatus.NotProvisionedDeviceDetected:
        this.iconSize = 'small';
        this.iconDisabled = true;
        break;
      case DataPortStatus.NotProvisionedNoDevice:
        this.iconSize = 'small';
        this.iconDisabled = true;
        break;
      case DataPortStatus.NotTestable:
        this.iconSize = 'small';
        this.iconMinus = true;
        break;
      case DataPortStatus.Loading: // Fall through
      default:
        this._iconStatus = DataPortStatus.Loading;
        this.iconSize = 'medium';
        break;
    }

    switch (this.iconSize)
    {
      case 'small':
        this.iconScale = 1.2;
        break;
      case 'medium':
        this.iconScale = 1.5;
        break;
      case 'large': // Fall through
      default:
        this.iconScale = 2;
        break;
    }

    this.iconTitle = this._iconStatus.replace(/-/g, ' ');
  }

}
