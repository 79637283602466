import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-var-debug',
  templateUrl: './var-debug.component.html',
  styleUrls: ['./var-debug.component.scss']
})
export class VarDebugComponent implements OnInit {

  @Input() debug: any[] | null;

  public isNull: Boolean = false;
  public isArray: Boolean = false;
  
  env = environment;

  constructor() { }

  ngOnInit() {
    this.isNull = this.debug == null;
    this.isArray = Array.isArray(this.debug);
  }

}

